import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'

export const Privacy = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Title>個人情報保護方針</Title>

          <Preamble>
            株式会社リモハブ（以下「当社」）は、当社事業である「オンライン管理型心臓リハビリシステムの企画・開発・製造・販売」を実施し、社会に寄与する企業として個人情報の適切な取扱いに規範を示すべき立場にあるとの理念の下、個人情報を適切に取り扱うことの重要性を認識し、個人情報保護方針を定め、役員及び従業員が一体となってこれを遵守し、もって個人情報の保護及び個人の権利利益の保護に万全を尽くしてまいります。
          </Preamble>

          <Article>
            <Number>1.</Number>
            <div>
              個人情報を取得するに当たっては、その利用目的をできる限り特定し、その目的の達成に必要な限度において個人情報を取得致します。
            </div>
          </Article>
          <Article>
            <Number>2.</Number>
            <div>
              個人情報を、本人から直接、書面によって取得する場合には、弊社名、個人情報保護管理者名及び連絡先、利用目的等をお知らせした上で、必要な範囲で個人情報を取得致します。
            </div>
          </Article>
          <Article>
            <Number>3.</Number>
            <div>
              個人情報の利用は、本人が同意を与えた利用目的の範囲内で行います。また、目的外利用を行わないため、必要な対策を講じる手順を確立し、実施致します。
            </div>
          </Article>
          <Article>
            <Number>4.</Number>
            <div>
              保有する個人情報を適切な方法で管理し、本人の同意なしに第三者に開示・提供致しません。
            </div>
          </Article>
          <Article>
            <Number>5.</Number>
            <div>
              保有する個人情報を利用目的に応じた必要な範囲内において、正確、かつ、最新の状態で管理し、個人情報の漏えい、滅失又は毀損などのおそれに対して、合理的な安全対策を講じ、予防並びに是正に努めます。
            </div>
          </Article>
          <Article>
            <Number>6.</Number>
            <div>
              個人情報の処理を外部へ委託する場合は、漏えいや第三者への提供を行わない等を契約により義務づけ、委託先に対する適切な管理を実施致します。
            </div>
          </Article>
          <Article>
            <Number>7.</Number>
            <div>
              保有する個人情報についての苦情・相談は、弊社の問合せ窓口に連絡頂くことにより、これに対応致します。
            </div>
          </Article>
          <Article>
            <Number>8.</Number>
            <div>
              個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
            </div>
          </Article>
          <Article>
            <Number>9.</Number>
            <div>
              個人情報保護マネジメントシステムを定め、これを定期的に見直し、継続的に改善致します。
            </div>
          </Article>

          <Signature>
            制定日：2022年9月1日
            <br />
            株式会社リモハブ
            <br />
            代表取締役　⾕⼝ 達典
            <br />
          </Signature>

          <Paragraph>
            <div>個人情報保護方針についてのお問合せ先</div>

            <div>
              株式会社リモハブ　個人情報相談受付窓口
              <br />
              〒532-0011
              <br />
              大阪市淀川区西中島4丁目3-24 GATE TERRACE SHIN OSAKA 3F
              <br />
              電話：06-7494-3400
              <br />
              メール：privacy@remohab.com
              <br />
            </div>
          </Paragraph>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
    padding: 0 20px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1rem;
    padding: 80px 0;
  `}
  ${customMedia.between('medium', 'large')`
    font-size: 2rem;
    margin: 0 auto;
    padding: 160px 0 80px;
    width: 735px;
  `}
  ${customMedia.greaterThan('large')`
    font-size: 2rem;
    margin: 0 auto;
    padding: 160px 0 80px;
    width: 1000px;
  `}

  color: #60656b;
`

const Title = styled.div`
  font-weight: bold;
  text-align: center;

  ${customMedia.lessThan('medium')`
    margin-bottom: 23px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 38px;
  `}
`

const Preamble = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 23px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 38px;
  `}
`

const Paragraph = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 40px;
  `}
`

const Article = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 16px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 24px;
  `}

  display: flex
`

const Number = styled.div`
  ${customMedia.lessThan('medium')`
    margin-right: 16px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-right: 24px;
  `}
`

const Signature = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 24px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 48px;
  `}

  text-align: right;
`
